import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

export default function DirectionSnackbar({ open, handleClose, message }) {
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
        key={message}
        autoHideDuration={2000}
      >
        <Alert onClose={handleClose} variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}
