import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import { useTheme } from "@material-ui/core/styles";
import postCache from "../lib/postCache";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";

const useStyles = makeStyles((theme) => ({
  root: {
    /* minWidth: 275,
    [theme.breakpoints.up("sm")]: {
      minWidth: "100%",
    },*/
  },
  card: {
    wordWrap: "break-word",
    height: "240px",
    borderTopLeftRadius: "24px",
    borderTopRightRadius: "24px",
    backgroundColor: "#ffeeff",
    borderTopStyle: "groove",
    borderLeftStyle: "groove",
    borderRightStyle: "groove",
    borderBottomStyle: "inset",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "right",

    borderBottomLeftRadius: "24px",
    borderBottomRightRadius: "24px",
    backgroundColor: "#ffeeff",
    borderBottomStyle: "groove",
    borderLeftStyle: "groove",
    borderRightStyle: "groove",
  },
}));

export default function CardPost({ postKey, url, handleClickOpen }) {
  const theme = useTheme();
  const [post, setCardContent] = useState({ content: "Loading..." });
  const [shouldLoadContent, setshouldLoadContent] = useState(true);
  const classes = useStyles(theme);

  const ref = useRef(null);

  useEffect(() => {
    const cachedPost = postCache.retrieve(postKey);
    if (cachedPost) {
      setshouldLoadContent(false);
      setCardContent(cachedPost);
      return;
    }

    if (shouldLoadContent) {
      setshouldLoadContent(false);

      axios({
        method: "GET",
        url: url,
      })
        .then(({ data }) => {
          postCache.save(data);
          setCardContent(data);
        })
        .catch((err) => {
          setCardContent({ content: "ERROR" });
        });
    }
  }, [shouldLoadContent, url, postKey]);

  return (
    <div className={classes.root} ref={ref}>
      <Card
        className={classes.card}
        onClick={() => {
          handleClickOpen(post);
        }}
      >
        <CardContent>
          <div dangerouslySetInnerHTML={{ __html: post.content }} />
        </CardContent>
      </Card>
      <Paper
        onClick={() => {
          handleClickOpen(post);
        }}
        className={classes.paper}
      >
        <Grid container>
          <Grid item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <AspectRatioIcon />
            </div>
          </Grid>
          <Grid item xs>
            <Grid container direction="row-reverse">
              <LocationOnIcon />
              {post.location && post.location.country_name
                ? post.location.country_name.toUpperCase()
                : "INTERNET"}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
