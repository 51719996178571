import React from "react";

function Yamseng() {
  return (
    <div style={{ textAlign: "center", fontSize: "40px" }}>
      <br />
      <br />
      <a href="http://djbeng.com/yamseng.html">YAM Seng!</a>
    </div>
  );
}

export default Yamseng;
