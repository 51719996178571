import {
  Box,
  Button,
  Container,
  Divider,
  Fab,
  Input,
  makeStyles,
  Paper,
  Typography,
  useTheme,
} from "@material-ui/core";
import LinearProgressWithLabel from "./components/LinearProgressWithLabel";
import React, {
  forwardRef,
  useState,
  useContext,
  useCallback,
  useEffect,
} from "react";
import AdmiralSnackBar from "./components/admiralSnackBar";
import api from "./lib/api";
import flower from "./images/flower.png";
import flower_upsidedown from "./images/flower_upsidedown.png";
import pinkBackground from "./images/pink_texture_background.jpg";

import { green } from "@material-ui/core/colors";
import clsx from "clsx";

import Image from "material-ui-image";

import Uploady, {
  useItemProgressListener,
  useBatchFinishListener,
  UploadyContext,
} from "@rpldy/uploady";
import { asUploadButton } from "@rpldy/upload-button";

import ButtonWithLoader from "./components/ButtonWithLoader";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  textInput: {
    textAlign: "center",
  },
  introParagraph: {
    textAlign: "center",
  },
  paper: {
    borderRadius: "24px",
    backgroundColor: "#ffeeff",
    borderStyle: "groove",
  },
  guestLibText: {
    paddingBottom: "0.5em",
    paddingTop: "0.5em",
    fontFamily: "Annie Use Your Telescope",
    fontSize: "1.6em",
  },
  specialGuestLibText: {
    paddingBottom: "0.5em",
    paddingTop: "0.5em",
    fontFamily: "Dancing Script",
    fontWeight: "bold",
  },
  Container: {
    "& Input": {
      fontStyle: "italic",
      textAlign: "center",
      fontFamily: "Annie Use Your Telescope",
      fontSize: "1.5em",
    },
  },
  pictureContainer: {
    width: 200,
    margin: "0 auto",
  },
  pictureButton: {
    height: 200,
    width: 200,
    background: "#fff0",
    border: "2px solid",
    borderColor: "grey",
    color: "black",
    "&:hover": {
      background: "#fff0",
      borderColor: "black",
    },
  },
  form: {
    paddingLeft: "2em",
    paddingRight: "2em",
  },
}));

function MadLib() {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [madlibData, setMadLibData] = useState({});
  const [isPostCreated, setDidPostCreate] = useState(false);
  const [isAdmiralSnackBarOpen, setAdmiralSnackBarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [preSignedURL, setPreSignedURL] = useState(null);
  const [imageKey, setImageKey] = useState(null);
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  const onSubmitClick = (event) => {
    event.preventDefault();
    setLoading(true);

    api.createMadLib(madlibData, imageKey).then((res) => {
      setDidPostCreate(true);
      setLoading(false);
      setAdmiralSnackBarOpen(true);
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    const newData = { ...madlibData, ...{ [name]: value } };

    if (value.trim() === "") {
      delete newData[name];
    }

    setIsSubmitDisabled(Object.keys(newData).length === 0);
    setMadLibData(newData);
  };

  const handleSnackBarClose = () => {
    setAdmiralSnackBarOpen(false);
  };

  const onPictureUploadComplete = (batch) => {
    setIsImageUploaded(true);
  };

  useEffect(() => {
    if (!preSignedURL) {
      api.getSignedUrl("madlib").then((res) => {
        setPreSignedURL(res.url);
        setImageKey(res.imageKey);
      });
    }
  }, [preSignedURL]);

  const imageDetails = {
    originalWidth: 1080,
    originalHeight: 566,
  };
  const imageContainerWidth = imageDetails.originalWidth / 7;

  const bottomImage = {
    originalWidth: 1368,
    originalHeight: 855,
  };
  const bottomImageContainerWidth = bottomImage.originalWidth / 8;

  return (
    <Container className={classes.Container} maxWidth="md">
      <Box my={3}>
        <Paper className={classes.paper} elevation={3}>
          <Box mx="auto" style={{ width: imageContainerWidth }}>
            <Image
              color="none"
              aspectRatio={
                imageDetails.originalWidth / imageDetails.originalHeight
              }
              src={flower}
            />
          </Box>

          <form className={classes.form} onSubmit={onSubmitClick}>
            <Typography
              className={classes.specialGuestLibText}
              style={{ textAlign: "center" }}
              variant="h4"
            >
              Congratulations
            </Typography>
            <Typography
              className={classes.guestLibText}
              style={{ textAlign: "center" }}
            >
              to the{" "}
              <Input
                name="intro"
                inputProps={{
                  "aria-label": "description",
                }}
                placeholder="(adj)"
                onChange={handleChange}
              />{" "}
              couple!
            </Typography>
            <br />
            <Typography className={classes.guestLibText}>
              When I heard that Patrick and Deirdre were getting married, I
              shouted{" "}
              <Input
                name="shout"
                inputProps={{
                  "aria-label": "description",
                }}
                placeholder="(exclamation)"
                onChange={handleChange}
              />
              !
            </Typography>
            <br />
            <Typography className={classes.guestLibText}>
              I{" "}
              <Input
                name="transport1"
                inputProps={{
                  "aria-label": "description",
                }}
                placeholder="(verb)"
                onChange={handleChange}
              />{" "}
              all the way from{" "}
              <Input
                name="transport2"
                inputProps={{
                  "aria-label": "description",
                }}
                placeholder="(noun)"
                onChange={handleChange}
              />{" "}
              to celebrate today.
            </Typography>
            <Typography className={classes.guestLibText}>
              I’m so{" "}
              <Input
                name="imso_blank"
                inputProps={{
                  "aria-label": "description",
                }}
                placeholder="(adj)"
                onChange={handleChange}
              />{" "}
              for you two!
            </Typography>
            <br />
            <Typography className={classes.guestLibText}>
              You two are the most{" "}
              <Input
                name="themost_blank1"
                inputProps={{
                  "aria-label": "description",
                }}
                placeholder="(adj)"
                onChange={handleChange}
              />{" "}
              <Input
                name="themost_blank2"
                inputProps={{
                  "aria-label": "description",
                }}
                placeholder="(noun)"
                onChange={handleChange}
              />{" "}
              and I wish you nothing less than{" "}
              <Input
                name="nothingless_blank1"
                inputProps={{
                  "aria-label": "description",
                }}
                placeholder="(adj)"
                onChange={handleChange}
              />{" "}
              <Input
                name="nothingless_blank2"
                inputProps={{
                  "aria-label": "description",
                }}
                placeholder="(noun)"
                onChange={handleChange}
              />{" "}
              for the rest of your lives together.
            </Typography>
            <br />
            <Box
              className={classes.pictureContainer}
              textAlign="center"
              padding={2}
            >
              {isImageUploaded ? (
                <Alert severity="success">Image Uploaded!</Alert>
              ) : (
                <Uploady
                  destination={{ method: "PUT", url: preSignedURL }}
                  sendWithFormData={false}
                  autoUpload={true}
                  accept="image/*"
                  capture="user"
                >
                  <DivUploadButton
                    className={classes.pictureButton}
                    variant="contained"
                    color="primary"
                  >
                    Take a Picture!
                  </DivUploadButton>
                  <UploadProgress
                    onUploadedCompleted={onPictureUploadComplete}
                  />
                </Uploady>
              )}
            </Box>
            <br />
            <Typography className={classes.guestLibText}>
              During the wedding my face looked like this because
              <Input
                name="mfw"
                inputProps={{
                  "aria-label": "description",
                }}
                placeholder="(reason)"
                onChange={handleChange}
              />
              .
            </Typography>
            <br />
            <br />
            <Divider variant="middle" />
            <br />
            <Typography
              className={classes.specialGuestLibText}
              style={{ textAlign: "center" }}
              variant="h4"
            >
              My best advice?{" "}
            </Typography>
            <Typography className={classes.guestLibText}>
              Given my{" "}
              <Input
                name="experience"
                inputProps={{
                  "aria-label": "description",
                }}
                placeholder="(quantity)"
                onChange={handleChange}
              />{" "}
              years of experience,
            </Typography>
            <Typography className={classes.guestLibText}>
              Patrick, you should always{" "}
              <Input
                name="patrick_should_always1"
                inputProps={{
                  "aria-label": "description",
                }}
                placeholder="(verb)"
                onChange={handleChange}
              />{" "}
              Deirdre’s{" "}
              <Input
                name="patrick_should_always2"
                inputProps={{
                  "aria-label": "description",
                }}
                placeholder="(noun)"
                onChange={handleChange}
              />
              .
            </Typography>
            <Typography className={classes.guestLibText}>
              Deirdre, you should always{" "}
              <Input
                name="deirdre_should_always1"
                inputProps={{
                  "aria-label": "description",
                }}
                placeholder="(verb)"
                onChange={handleChange}
              />{" "}
              Patrick’s{" "}
              <Input
                name="deirdre_should_always2"
                inputProps={{
                  "aria-label": "description",
                }}
                placeholder="(noun)"
                onChange={handleChange}
              />
              .
            </Typography>
            <br />
            <Typography className={classes.guestLibText}>
              To keep a happy marriage, never ever fight about
              <Input
                name="never_fight"
                inputProps={{
                  "aria-label": "description",
                }}
                placeholder="(noun)"
                onChange={handleChange}
              />
              .
            </Typography>
            <br />
            <Typography className={classes.guestLibText}>
              When you have children together, you can even name them
              <Input
                name="children1"
                inputProps={{
                  "aria-label": "description",
                }}
                placeholder="(girl name)"
                onChange={handleChange}
              />{" "}
              &{" "}
              <Input
                name="children2"
                inputProps={{
                  "aria-label": "description",
                }}
                placeholder="(boy name)"
                onChange={handleChange}
              />{" "}
              because I say so.
            </Typography>
            <Typography className={classes.specialGuestLibText} variant="h2">
              Love, <br />
              <Input
                name="from"
                inputProps={{
                  "aria-label": "description",
                }}
                placeholder="(name)"
                onChange={handleChange}
                style={{ fontSize: "0.7em" }}
                className={classes.specialGuestLibText}
              />
            </Typography>

            <Box textAlign="center" padding={2}>
              <ButtonWithLoader
                loading={loading}
                success={isPostCreated}
                onClick={onSubmitClick}
                disabled={isSubmitDisabled}
              >
                {isPostCreated ? "Sent!" : "Send!"}
              </ButtonWithLoader>
            </Box>
          </form>
          <AdmiralSnackBar
            open={isAdmiralSnackBarOpen}
            handleClose={handleSnackBarClose}
            message="Post created!"
          />
          <Box mx="auto" style={{ width: bottomImageContainerWidth }}>
            <Image
              color="none"
              aspectRatio={
                bottomImage.originalWidth / bottomImage.originalHeight
              }
              src={flower_upsidedown}
            />
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

const UploadProgress = ({ onUploadedCompleted }) => {
  const [progress, setProgess] = useState(0);
  const progressData = useItemProgressListener();

  useBatchFinishListener((batch) => {
    onUploadedCompleted(batch);
  });

  if (progressData && progressData.completed > progress) {
    setProgess(() => progressData.completed);
  }

  if (progress >= 100) {
    return <Typography>Image Uploaded!</Typography>;
  }

  return progressData && <LinearProgressWithLabel value={progress} />;
};

const DivUploadButton = asUploadButton(
  forwardRef((props, ref) => (
    <UploadButton {...props}>{props.children}</UploadButton>
  ))
);

export default MadLib;

const UploadButton = (props) => {
  const uploady = useContext(UploadyContext);

  return <Button {...props}>{props.children}</Button>;
};
