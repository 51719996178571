import "./App.css";
import React, { useState, useEffect } from "react";
import { Switch, Route, Link, Redirect, useLocation } from "react-router-dom";
import backgroundImage from "./images/backgroundImage.png";

import queryString from "query-string";
import Board from "./Board";
import Yamseng from "./Yamseng";
import MadLib from "./MadLib";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "@material-ui/core/Container";

import api from "./lib/api";

const useStyles = makeStyles({
  "@global": {
    body: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "4000px",
      backgroundAttachment: "fixed",
      height: "100%",
    },
    html: {
      height: "100%",
    },
    "#componentWithId": {
      height: "100%",
    },
  },
  root: {
    flexGrow: 1,
  },
});

const tabs = [
  {
    path: "/yamseng",
    label: "Yam Seng",
    component: Yamseng,
  },
  {
    path: "/board",
    label: "Board",
    component: Board,
  },
  {
    path: "/madlib",
    label: "Guest Lib",
    component: MadLib,
  },
];

const shouldRedirectToBoard = (path) => {
  return path === "/" || !isValidPath(path);
};

const isValidPath = (path) => {
  return !!tabs.find((tab) => tab.path === path);
};

function App() {
  const location = useLocation();

  const startingTab = tabs.find((tab) => tab.label === "Board");

  const classes = useStyles();
  const [value, setValue] = React.useState(
    shouldRedirectToBoard(location.pathname)
      ? startingTab.path
      : location.pathname
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const { key } = queryString.parse(location.search);

    if (key) api.saveKey(key);
  }, [location]);

  return (
    <Container disableGutters={true} maxWidth="xl">
      {shouldRedirectToBoard(location.pathname) && (
        <Redirect to={startingTab.path} />
      )}
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {tabs.map((tab) => (
            <Tab
              label={tab.label}
              value={tab.path}
              component={Link}
              to={tab.path}
            />
          ))}
        </Tabs>
      </Paper>
      <Switch>
        {tabs.map((tab) => (
          <Route exact path={tab.path}>
            {tab.component && <tab.component />}
          </Route>
        ))}
      </Switch>
    </Container>
  );
}

export default App;
