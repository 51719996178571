import axios from "axios";

const saveKey = (key) => {
  localStorage.setItem("key", key);
};
const getKey = () => {
  return localStorage.getItem("key");
};

const listPosts = async (lastKey) => {
  const key = getKey();
  const { data } = await axios({
    method: "post",
    url:
      "https://4qzhy9x2b7.execute-api.ap-southeast-1.amazonaws.com/prod/posts/list",
    data: { lastKey },
    headers: { "x-api-key": key },
  });

  return data;
};

const createPost = async (content) => {
  const key = getKey();
  const { data } = await axios({
    method: "post",
    url:
      "https://4qzhy9x2b7.execute-api.ap-southeast-1.amazonaws.com/prod/posts/create",
    data: {
      content,
    },
    headers: { "x-api-key": key },
  });

  return data;
};

const createMadLib = async (content, imageKey) => {
  const key = getKey();
  const { data } = await axios({
    method: "post",
    url:
      "https://4qzhy9x2b7.execute-api.ap-southeast-1.amazonaws.com/prod/madlib/create",
    data: {
      content,
      imageKey,
    },
    headers: { "x-api-key": key },
  });

  return data;
};

const getSignedUrl = async (type) => {
  const key = getKey();
  const { data } = await axios({
    method: "get",
    url:
      "https://4qzhy9x2b7.execute-api.ap-southeast-1.amazonaws.com/prod/images/getSignedURL",
    headers: { "x-api-key": key },
    params: {
      type,
    },
  });

  return data;
};

const api = {
  saveKey,
  listPosts,
  createPost,
  createMadLib,
  getSignedUrl,
};

export default api;
