import { Button, makeStyles, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { green, red } from "@material-ui/core/colors";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 200,
    height: 50,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonError: {
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
}));

export default function ButtonWithLoader({
  loading,
  success,
  error,
  onClick,
  children,
  disabled,
}) {
  const classes = useStyles();

  const buttonClassname = clsx({
    [classes.root]: true,
    [classes.buttonSuccess]: success,
    [classes.buttonError]: error,
  });

  return (
    <div className={classes.wrapper}>
      <Button
        variant="contained"
        color="primary"
        className={buttonClassname}
        disabled={disabled || loading}
        onClick={onClick}
        size="large"
      >
        {children}
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
}
