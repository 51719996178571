import React, { useState, useEffect, ReactDOM, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

import CardManager from "./components/cardManager";
import AdmiralSnackBar from "./components/admiralSnackBar";
import ButtonWithLoader from "./components/ButtonWithLoader";
import api from "./lib/api";
import { Paper } from "@material-ui/core";

function Board() {
  const [htmlValue, setHtmlValue] = useState("");
  const [error, setError] = useState(null);
  const [refreshPosts, setShouldRefreshPosts] = useState(false);
  const [isPostCreated, setDidPostCreate] = useState(false);
  const [isPostCreating, setIsPostCreating] = useState(false);
  const [isSendDisabled, setIsSendDisabled] = useState(true);
  const quillRef = useRef(null);

  const DirectionAttribute = Quill.import("attributors/attribute/direction");
  Quill.register(DirectionAttribute, true);
  const AlignClass = Quill.import("attributors/class/align");
  Quill.register(AlignClass, true);
  const BackgroundClass = Quill.import("attributors/class/background");
  Quill.register(BackgroundClass, true);
  const ColorClass = Quill.import("attributors/class/color");
  Quill.register(ColorClass, true);
  const DirectionClass = Quill.import("attributors/class/direction");
  Quill.register(DirectionClass, true);
  const FontClass = Quill.import("attributors/class/font");
  Quill.register(FontClass, true);
  const SizeClass = Quill.import("attributors/class/size");
  Quill.register(SizeClass, true);
  const AlignStyle = Quill.import("attributors/style/align");
  Quill.register(AlignStyle, true);
  const BackgroundStyle = Quill.import("attributors/style/background");
  Quill.register(BackgroundStyle, true);
  const ColorStyle = Quill.import("attributors/style/color");
  Quill.register(ColorStyle, true);
  const DirectionStyle = Quill.import("attributors/style/direction");
  Quill.register(DirectionStyle, true);
  const FontStyle = Quill.import("attributors/style/font");
  Quill.register(FontStyle, true);
  const SizeStyle = Quill.import("attributors/style/size");
  Quill.register(SizeStyle, true);

  const onSendButtonClick = () => {
    setIsPostCreating(true);

    api
      .createPost(htmlValue)
      .then((data) => {
        setDidPostCreate(true);
        setShouldRefreshPosts(true);
        setError(null);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsPostCreating(false);
      });
  };

  const handleQuillChange = (content, delta, source, editor) => {
    setHtmlValue(content);
    setIsSendDisabled(editor.getText().trim() === "");
  };

  useEffect(() => {
    //if (quillRef) quillRef.editor.format("align", "right");
  }, []);

  const handleSnackBarClose = () => {
    setDidPostCreate(false);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      [{ color: [] }, { background: [] }],
      ["bold", "italic", "underline", "strike"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
    ],
  };

  const formats = [
    "header",
    "color",
    "background",
    "align",
    "bold",
    "italic",
    "underline",
    "strike",
  ];

  return (
    <div>
      <br />
      <br />
      <Container disableGutters={true} maxWidth="md">
        <Box>
          <Paper elevation={3}>
            <ReactQuill
              ref={quillRef}
              preserveWhitespace="true"
              theme="snow"
              onChange={handleQuillChange}
              placeholder="Write a message :)"
              modules={modules}
              formats={formats}
            />
          </Paper>
        </Box>

        <br />
        <br />
        <Box textAlign="center">
          <ButtonWithLoader
            loading={isPostCreating}
            success={isPostCreated}
            error={error}
            onClick={onSendButtonClick}
            disabled={isSendDisabled}
          >
            Send
          </ButtonWithLoader>
        </Box>

        <AdmiralSnackBar
          open={isPostCreated}
          handleClose={handleSnackBarClose}
          message="Post created!"
        />
        {error && <h2> Error occured, please try again </h2>}
      </Container>
      <br />
      <br />
      <CardManager
        setShouldRefreshPosts={setShouldRefreshPosts}
        refreshPosts={refreshPosts}
      />
    </div>
  );
}

/*

*/

export default Board;
