const cache = {};

const postCache = {
  save: (postObject) => {
    cache[postObject.key] = postObject;
  },
  retrieve: (key) => {
    return cache[key];
  },
};

export default postCache;
