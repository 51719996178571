import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  InfiniteLoader,
  List,
  WindowScroller,
  AutoSizer,
} from "react-virtualized";
import Card from "./card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
const useStyles = makeStyles((theme) => ({
  grid: {
    //marginTop: theme.spacing(1),
    //marginBottom: theme.spacing(1),
    justifyContent: "center",
  },
  gridItem: {
    padding: theme.spacing(1),

    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  row: {
    display: "flex",
    justifyContent: "center",
  },
}));

function generateIndexesForRow(rowIndex, rowWidth, itemWidth, itemsAmount) {
  const result = [];
  const maxItemsPerRow = getMaxItemsAmountPerRow(rowWidth, itemWidth);
  const startIndex = rowIndex * maxItemsPerRow;

  for (
    let i = startIndex;
    i < Math.min(startIndex + maxItemsPerRow, itemsAmount);
    i++
  ) {
    result.push(i);
  }

  return result;
}

function getMaxItemsAmountPerRow(rowWidth, itemWidth) {
  return Math.max(Math.floor(rowWidth / itemWidth), 1);
}

function getRowsAmount(rowWidth, itemWidth, itemsAmount, hasMore) {
  const maxItemsPerRow = getMaxItemsAmountPerRow(rowWidth, itemWidth);

  return Math.ceil(itemsAmount / maxItemsPerRow) + (hasMore ? 1 : 0);
}

const RowItem = React.memo(function RowItem({
  postKey,
  url,
  className,
  itemComponentType: ItemComponentType,
  width,
  handleClickOpen,
}) {
  return (
    <Grid item className={className} style={{ width }}>
      <ItemComponentType
        handleClickOpen={handleClickOpen}
        postKey={postKey}
        url={url}
      />
    </Grid>
  );
});

const InfinitePostList = ({
  itemWidth,
  itemHeight,
  hasMore,
  posts,
  itemComponentType,
  reset,
  isFetching,
  fetchMovies,
  handlePostClickOpen,
  width,
}) => {
  const classes = useStyles();
  const infiniteLoaderRef = useRef();

  useEffect(() => {
    if (reset && infiniteLoaderRef.current) {
      infiniteLoaderRef.current.resetLoadMoreRowsCache(true);
    }
  }, [reset, infiniteLoaderRef]);

  const loadMoreRows = () => {
    if (!isFetching) {
      fetchMovies();
    }
  };

  const noRowsRenderer = () => (
    <Grid item>
      <Typography>No posts found</Typography>
    </Grid>
  );

  return (
    <section>
      <AutoSizer disableHeight>
        {({ width: rowWidth }) => {
          const rowCount = getRowsAmount(
            rowWidth,
            itemWidth,
            posts.length,
            hasMore
          );

          return (
            <InfiniteLoader
              ref={infiniteLoaderRef}
              rowCount={rowCount}
              isRowLoaded={({ index }) => {
                const indexesForRows = generateIndexesForRow(
                  index,
                  rowWidth,
                  itemWidth,
                  posts.length
                );
                const allItemsLoaded = indexesForRows.length > 0;
                return !hasMore || allItemsLoaded;
              }}
              loadMoreRows={loadMoreRows}
              threshold={1}
            >
              {({ onRowsRendered, registerChild }) => (
                <WindowScroller>
                  {({ height, scrollTop }) => (
                    <List
                      className={classes.grid}
                      autoHeight
                      ref={registerChild}
                      height={height}
                      scrollTop={scrollTop}
                      width={rowWidth}
                      rowCount={rowCount}
                      rowHeight={itemHeight + 16}
                      onRowsRendered={onRowsRendered}
                      rowRenderer={({ index, style, key }) => {
                        const indexesForRows = generateIndexesForRow(
                          index,
                          rowWidth,
                          itemWidth,
                          posts.length
                        );

                        const postsForRow = indexesForRows.map(
                          (postIndex) => posts[postIndex]
                        );

                        return (
                          <div style={style} key={key} className={classes.row}>
                            {postsForRow.map((post) => (
                              <RowItem
                                key={post.key}
                                url={post.url}
                                handleClickOpen={handlePostClickOpen}
                                className={classes.gridItem}
                                itemComponentType={itemComponentType}
                                width={
                                  isWidthDown("sm", width) ? "100%" : itemWidth
                                }
                                postKey={post.key}
                              />
                            ))}
                          </div>
                        );
                      }}
                      noRowsRenderer={noRowsRenderer}
                    />
                  )}
                </WindowScroller>
              )}
            </InfiniteLoader>
          );
        }}
      </AutoSizer>
    </section>
  );
};

InfinitePostList.defaultProps = {
  posts: [],
  isFetching: false,
  hasMore: false,
  reset: false,
  fetchMovies: () => {},
  itemComponentType: Card,
  itemWidth: 480,
  itemHeight: 282,
};

InfinitePostList.propTypes = {
  posts: PropTypes.array,
  fetchMovies: PropTypes.func,
  hasMore: PropTypes.bool,
  isFetching: PropTypes.bool,
  reset: PropTypes.bool,
  itemComponentType: PropTypes.elementType,
  itemWidth: PropTypes.number,
  itemHeight: PropTypes.number,
};

export default withWidth()(InfinitePostList);
