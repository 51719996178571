import React, { useState, useEffect, ReactDOM, useRef } from "react";

import CardPost from "./card";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import PostFocusDialog from "./postFocusDialog";
import InfinitePostList from "./InfinitePostList";
import api from "../lib/api";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    width: "100%",
  },
}));

const INITAL_STATE = {
  open: false,
  focusedPost: {},
  hasMore: true,
  lastKey: null,
  isFetching: false,
  reset: false,
  posts: [],
  error: false,
};

export default function CardManager({ refreshPosts, setShouldRefreshPosts }) {
  const classes = useStyles();
  const [open, setOpen] = useState(INITAL_STATE.open);
  const [focusedPost, setFocusedPost] = useState(INITAL_STATE.focusedPost);
  const [hasMore, setHasMore] = useState(INITAL_STATE.hasMore);
  const [lastKey, setLastKey] = useState(INITAL_STATE.lastKey);
  const [isFetching, setIsFetching] = useState(INITAL_STATE.isFetching);
  const [reset, setReset] = useState(INITAL_STATE.reset);
  const [posts, setPosts] = useState(INITAL_STATE.posts);
  const [error, setError] = useState(INITAL_STATE.error);

  const handleClose = () => {
    setOpen(false);
    setFocusedPost({});
  };

  const handleClickOpen = (clickedPost) => {
    setFocusedPost(clickedPost);
    setOpen(true);
  };

  const loadMore = () => {
    setIsFetching(true);

    return api
      .listPosts(lastKey)
      .then((res) => {
        setPosts(posts.concat(res.posts));

        setLastKey(res.posts[res.posts.length - 1].key);
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    if (refreshPosts) {
      setOpen(INITAL_STATE.open);
      setFocusedPost(INITAL_STATE.focusedPost);
      setHasMore(INITAL_STATE.hasMore);
      setLastKey(INITAL_STATE.lastKey);
      setIsFetching(INITAL_STATE.isFetching);
      setPosts(INITAL_STATE.posts);
      setError(INITAL_STATE.error);
      setReset(INITAL_STATE.reset);
      setShouldRefreshPosts(false);
    }
  }, [refreshPosts, setShouldRefreshPosts]);

  return (
    <div className={classes.root}>
      <PostFocusDialog
        post={focusedPost}
        handleClose={handleClose}
        open={open}
      />

      <InfinitePostList
        posts={posts}
        hasMore={hasMore}
        isFetching={isFetching}
        reset={reset}
        fetchMovies={loadMore}
        handlePostClickOpen={handleClickOpen}
      />

      {/*<Grid container spacing={3}>
        {posts.map((post) => (
          <Grid className={classes.gridItem} key={post.key} item md={4}>
            <CardPost handleClickOpen={handleClickOpen} url={post.url} />
          </Grid>
        ))}
        </Grid>*/}
    </div>
  );
}
